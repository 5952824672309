export const useScrollManager = () => {
   const scrollToTop = ()=>{
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
   }

   return {
    scrollToTop
   }
}